import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import fetch from "../../configs/axios";

function stringToBase64(input) {
  return Buffer.from(input).toString("base64");
}

// Login Form
export default class LoginForm extends Component {
  constructor(props) {
    super(props);

    console.log("============hit============");

    this.state = {
      email: "",
      password: "",
      isAuthenticated: false,
      errorLog: "",
      loadingBar: false,
    };
  }

  handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = {
        email: this.state.email,
        password: stringToBase64(this.state.password),
      };

      const { data } = await fetch.post("/evident/auth/api/v1/user/login/", formData);

      localStorage.setItem("token", data.data.access_token);
      localStorage.setItem("refresh_token", data.data.refresh_token);
      localStorage.setItem("isAuthenticated", "true");
      this.props.history.push("/dashboard");
    } catch (error) {
      if (error.response) {
        this.setState({
          isAuthenticated: error.response.data.success,
          errorLog: error.response.data.message,
          loadingBar: false,
        });
      }
    }
  };

  handleFormData = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    return (
      <div>
        {this.state.loadingBar ? (
          <div className="min-h-screen flex items-center justify-center">
            <Loader
              type="MutatingDots"
              color="#00BFFF"
              height={80}
              width={80}
              visible={this.state.loadingBar}
            />
          </div>
        ) : (
          <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
              <div>
                <img className="mx-auto h-12 w-auto" src="/logo192.png" alt="Workflow" />
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                  Sign in to your account
                </h2>
                <p className="mt-2 text-center text-sm text-gray-600">
                  Or{" "}
                  <Link to="/register">
                    <span className="font-medium evident-color">Register a new account</span>
                  </Link>
                </p>
              </div>
              {/* Login Form */}
              <form className="mt-8 space-y-6" onSubmit={this.handleFormSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm">
                  <div className="mb-3">
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-400 focus:border-gray-400 focus:z-10 sm:text-sm"
                      placeholder="Email address"
                      value={this.state.email}
                      onChange={this.handleFormData}
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-400 focus:border-gray-400 focus:z-10 sm:text-sm"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.handleFormData}
                    />
                  </div>
                </div>

                {!this.state.success && (
                  <div className="flex items-center justify-between">
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-red-500">
                      {this.state.errorLog}
                    </label>
                  </div>
                )}

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-700">
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm">
                    <Link to="/forget-password">
                      <span className="font-medium evident-color">Forgot your password?</span>
                    </Link>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white evident-bg hover:evident-bg focus:outline-none"
                    onSubmit={this.handleFormSubmit}
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}
