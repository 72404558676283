import axios from "axios";

const fetch = axios.create({
  baseURL: "https://auth.evidentbd.com",
  headers: {
    "service-name": "evident_blog",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

export const blogFetch = axios.create({
  baseURL: "https://api.evidentbd.com",
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

export default fetch;
