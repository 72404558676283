import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";

export default class BlogCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disable: true,
      qState: "",
      authorname: "",
      title: "",
      channel: [],
      socialLinks: [],
      socialLinksUUID: [],
      blogLinksUUID: [],
      selectedChannel: "",
      slug: "",
      featuredImage: null,
      startDate: new Date(),
      schedule_date: null,
      metaDescription: "",
      metaKeywords: "",
      authorError: "",
      slugError: "",
      titleError: "",
      Error: "",
      ContentError: "",
      metadescriptionError: "",
      metakeywordsError: "",
      tags: "",
    };
    this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
  }

  handleChange = (value) => {
    this.setState({ qState: value });
  };

  handleLinksChange = (uuid, type) => {
    this.setState((prevState) => {
      const currentUUIDs =
        type === "social" ? prevState.socialLinksUUID : prevState.blogLinksUUID;

      // Check if the uuid is already in the selected array
      if (currentUUIDs.includes(uuid)) {
        // Remove it from the array
        return {
          [type === "social" ? "socialLinksUUID" : "blogLinksUUID"]: currentUUIDs.filter(
            (id) => id !== uuid
          ),
        };
      } else {
        // Add it to the array
        return {
          [type === "social" ? "socialLinksUUID" : "blogLinksUUID"]: [
            ...currentUUIDs,
            uuid,
          ],
        };
      }
    });
  };

  handleSubmit() {
    const token = localStorage.getItem("token");

    const payload = {
      title: this.state.title,
      slug: this.state.slug,
      content: this.state.qState,
      author: this.state.authorname,
      img_path: this.state.featuredImage,
      created_by: {
        key: "authapicheck123",
      },
      updated_by: null,
      channels: this.state.selectedChannel,
      created_at: this.state.startDate,
      published_at: this.state.schedule_date,
      meta_description: this.state.metaDescription,
      meta_keyword: this.state.metaKeywords,
      tag: this.state.tags,

      social_service: this.state.socialLinksUUID,
      blog_service: this.state.blogLinksUUID,
    };

    // console.log(payload);

    axios
      .post(
        process.env.REACT_APP_BLOG_API_BASE_URL + "/blog/api/v1/admin/add_blog",
        payload,

        {
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        }
      )

      .then((res) => {
        //console.log(res, 'successfully submitted')
        this.setState({
          slugError: "",
          titleError: "",
          authorError: "",
          metadescriptionError: "",
          metakeywordsError: "",
        });
        toast("Blog Created Successfully !!");

        this.props.history.push("/blogs");
      })

      .catch(
        function (error) {
          // handle error
          console.log(error);
          if (error.response) {
            this.authorizationCheck(error.response.status);
          }

          if (error.response.data["author"]) {
            this.setState({
              authorError: error.response.data["author"],
            });
            toast.error(<div>Author: {this.state.authorError}</div>);
          } else {
            this.setState({
              authorError: "",
            });
          }
          if (error.response.data["slug"]) {
            this.setState({
              slugError: error.response.data["slug"],
            });
            toast.error(<div>Slug: {this.state.slugError}</div>);
          } else {
            this.setState({
              slugError: "",
            });
          }
          if (error.response.data["title"]) {
            this.setState({
              titleError: error.response.data["title"],
            });
            toast.error(<div>Title: {this.state.titleError}</div>);
          } else {
            this.setState({
              titleError: "",
            });
          }

          if (error.response.data["meta_description"]) {
            this.setState({
              metadescriptionError: error.response.data["meta_description"],
            });
            toast.error(<div>Meta Description: {this.state.metadescriptionError}</div>);
          } else {
            this.setState({
              metadescriptionError: "",
            });
          }

          if (error.response.data["meta_keywords"]) {
            this.setState({
              metakeywordsError: error.response.data["meta_keywords"],
            });
            toast.error(<div>Meta Keywords: {this.state.metakeywordsError}</div>);
          } else {
            this.setState({
              metakeywordsError: "",
            });
          }
          // else {
          //     toast.dark("Something unexpected occurs, Please try Again");
          // }
        }.bind(this)
      );
  }

  componentDidMount() {
    const token = localStorage.getItem("token");

    axios
      .get(`https://api.evidentbd.com/blog/api/v1/social/get-all-service-data/`)
      .then((res) => {
        const { data } = res;

        this.setState({
          socialLinks: data?.data,
        });
      });
    axios
      .get(process.env.REACT_APP_BLOG_API_BASE_URL + "/blog/api/v1/admin/channels", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        // console.log(res.data)
        this.setState({ channel: res.data.data });
        this.setState({ selectedChannel: res.data[0].id });
        //console.log("channels", res.data[0].id)
      })
      .catch(
        function (error) {
          // handle error
          if (error.response) {
            this.authorizationCheck(error.response.status);
          }
        }.bind(this)
      );
  }

  authorizationCheck(status) {
    if (status === 401) {
      axios
        .post(
          process.env.REACT_APP_AUTH_BASE_URL + "/api/v1/auth/refresh-token/",
          {
            refresh_token: localStorage.getItem("refresh_token"),
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          //console.log(res.data.data.access_token)
          localStorage.setItem("token", res.data.data.access_token);
          localStorage.setItem("refresh_token", res.data.data.refresh_token);
        })
        .catch(
          function (error) {
            // handle error
            localStorage.clear();
            this.props.history.push("/");
          }.bind(this)
        );
    }
  }

  handleDropdownChange = ({ target }) => {
    this.setState({
      selectedChannel: target.value,
    });
  };

  handleFeaturedImageChange = async (event) => {
    async function getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
    let image = await getBase64(event.target.files[0]);
    // console.log(image);

    const token = localStorage.getItem("token");
    let res = await axios.post(
      "https://transmitter.evidentbd.com/api/v1/upload/image",
      {
        service: "evident-blog",
        encoded_image: image,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
          // 'Content-Type': 'application/json'
        },
      }
    );

    const image_url = res.data.data.edge_url;

    await this.setState({
      featuredImage: image_url,
    });
  };

  async uploadImageCallBack(file) {
    async function getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
    let image = await getBase64(file);

    return new Promise(async (resolve, reject) => {
      const token = localStorage.getItem("token");

      //let response = null;

      let image_response = await axios.post(
        "https://transmitter.evidentbd.com/api/v1/upload/image",
        {
          service: "evident-blog",
          encoded_image: image,
        },
        {
          headers: {
            "secret-key": "GYGWYERY58454FDS4FD8V487FF8WQ8EF11D88W1D",
          },
        }
      );

      const image_url = image_response.data.data.edge_url;
      // console.log(image_url);
      resolve({ data: { link: image_url } });
    });
  }

  onFormFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      if (
        this.state.title !== "" &&
        this.state.slug !== "" &&
        this.state.metaDescription !== "" &&
        this.state.metaKeywords !== "" &&
        this.state.tags !== "" &&
        this.state.authorname !== "" &&
        this.state.startDate !== "" &&
        this.state.selectedChannel !== ""
      )
        this.setState({ disable: false });
      else this.setState({ disable: true });
    });
  };

  render() {
    return (
      <div className="mt-3 mb-6">
        <div className="grid lg:grid-cols-2 gap-4 mt-4">
          <nav className="bg-grey-light rounded font-sans w-full pl-1">
            <ol className="list-reset flex text-gray-500">
              <li>
                <Link to="/dashboard">
                  <span className="hover:text-gray-800">Dashboard</span>
                </Link>
              </li>
              <span className="mx-2">/</span>
              <li>
                <Link to="/blogs">
                  <span className="hover:text-gray-800">Blogs</span>
                </Link>
              </li>
              <span className="mx-2">/</span>
              <li>Create Blog</li>
            </ol>
          </nav>
        </div>

        <div className="flex gap-6">
          <h1 className="text-xl font-bold leading-tight mb-5 mt-5 w-9/12">
            Create Blog
          </h1>
          <h1 className="text-xl font-bold leading-tight mb-5 mt-5 w-3/12">Share</h1>
        </div>
        <div className="flex gap-6">
          <div className="h-1/2 editor bg-white p-5 rounded rounded-md w-9/12">
            <div className="grid lg:grid-cols-2 gap-4 mt-4 mb-4">
              <div className="w-full">
                <label className="uppercase text-gray-400 text-xs">Title</label>
                <input
                  title="Title of the Blog"
                  className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                  placeholder="Title of the Blog"
                  // value={searchTerm}
                  // onChange={handleChange}
                  name="title"
                  onChange={this.onFormFieldChange}
                />
                {this.state.titleError !== "" ? (
                  <label className="text-red-500 text-sm">{this.state.titleError}</label>
                ) : (
                  <label className="text-red-500 text-sm"></label>
                )}
              </div>

              <div className="w-full">
                <label className="uppercase text-gray-400 text-xs">Slug</label>
                <input
                  title="Slug"
                  className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                  placeholder="Slug"
                  maxLength="50"
                  // value={searchTerm}
                  // onChange={handleChange}
                  name="slug"
                  onChange={this.onFormFieldChange}
                />
                {this.state.slugError !== "" ? (
                  <label className="text-red-500 text-sm">{this.state.slugError}</label>
                ) : (
                  <label className="text-red-500 text-sm"></label>
                )}
              </div>
            </div>

            <div className="grid lg:grid-cols-3 gap-4 mt-4 mb-4">
              <div className="w-full">
                <input
                  title="Meta Description"
                  className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                  placeholder="Meta Description"
                  maxLength="155"
                  // value={searchTerm}
                  // onChange={handleChange}
                  name="metaDescription"
                  onChange={this.onFormFieldChange}
                />
                {this.state.metadescriptionError !== "" ? (
                  <label className="text-red-500 text-sm">
                    {this.state.metadescriptionError}
                  </label>
                ) : (
                  <label className="text-red-500 text-sm"></label>
                )}
              </div>

              <div className="w-full">
                <input
                  title="Meta Keywords"
                  className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                  placeholder="Meta Keywords"
                  maxLength="100"
                  name="metaKeywords"
                  // value={searchTerm}
                  // onChange={handleChange}
                  onChange={this.onFormFieldChange}
                />
                {this.state.metakeywordsError !== "" ? (
                  <label className="text-red-500 text-sm">
                    {this.state.metakeywordsError}
                  </label>
                ) : (
                  <label className="text-red-500 text-sm"></label>
                )}
              </div>

              <div className="w-full">
                <input
                  title="Input Tags"
                  className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                  placeholder="Input Tags"
                  maxLength="100"
                  value={this.state.tags}
                  name="tags"
                  onChange={this.onFormFieldChange}
                />
              </div>
            </div>

            <div className="grid lg:grid-cols-4 gap-4 mt-4 mb-4">
              <div className="w-full">
                <input
                  title="Author's Name"
                  className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                  placeholder="Author's Name"
                  name="authorname"
                  onChange={this.onFormFieldChange}
                />
                {this.state.authorError !== "" ? (
                  <label className="text-red-500 text-sm">{this.state.authorError}</label>
                ) : (
                  <label className="text-red-500 text-sm"></label>
                )}
              </div>

              <div className="z-50 grid lg:grid-cols-1">
                <DatePicker
                  title="Publish Date"
                  className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                  selected={this.state.startDate}
                  onChange={this.onFormFieldChange}
                  name="startDate"
                  isClearable
                  placeholderText="Publish Date"
                />
              </div>

              <div className="w-full">
                <select
                  title="Select Channel"
                  className="w-full rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                  type="button"
                  value={this.state.selectedChannel}
                  name="selectedChannel"
                  onChange={this.onFormFieldChange}
                >
                  <option className="py-1" value="" disabled>
                    Select Channel
                  </option>
                  {/* <option className="py-1" value="all">Select Channel</option> */}
                  {this.state.channel.map(({ name, id }, index) => (
                    <option className="py-1" value={id} key={id}>
                      {name}
                    </option>
                  ))}
                  {/* {this.state.channel.map(({ name, id }, index) => <option className="py-1" value={id} >{name}</option>)} */}
                </select>
                <label className="text-red-500 text-sm"></label>
              </div>

              <div className="w-full">
                <input
                  type="file"
                  title="Feature Image"
                  className="w-full bg-white py-2 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
                  placeholder="Feature Image"
                  name="featuredImage"
                  onChange={this.handleFeaturedImageChange}
                />
              </div>
            </div>

            <div className="grid lg:grid-cols-1 gap-4 mt-4 mb-4">
              {/* quill editor */}
              <div className="text-editor" style={{}}>
                <EditorToolbar />
                <ReactQuill
                  style={{ height: "43vh" }}
                  theme="snow"
                  value={this.state.qState}
                  onChange={this.handleChange}
                  placeholder={"Write something awesome..."}
                  modules={modules}
                  formats={formats}
                />
              </div>
            </div>
          </div>

          <div className="bg-white p-5 rounded rounded-md w-3/12">
            {/* Blog Links Section */}
            <div>
              <label className="text-gray-800 font-semibold text-base">
                Share with other blog sites:
              </label>
              <div className="flex flex-col gap-1.5 mt-2 mb-5">
                {this.state.socialLinks?.map((item) => {
                  return item?.service_status === "BLOG" ? (
                    <label
                      key={item?.uuid}
                      className="flex items-center gap-1.5"
                      htmlFor={item?.uuid}
                    >
                      <input
                        value={item?.uuid}
                        onChange={(e) => this.handleLinksChange(e.target.value, "blog")}
                        type="checkbox"
                        id={item?.uuid}
                        checked={this.state.blogLinksUUID.includes(item?.uuid)} // Control the checked state
                      />
                      <span className="text-gray-800 text-sm">{item?.service_name}</span>
                      <span className="text-gray-500 text-xs">
                        ({item?.author_email})
                      </span>
                    </label>
                  ) : null; // Return null for non-BLOG items
                })}
              </div>
            </div>

            {/* Social Links Section */}
            <div>
              <label className="text-gray-800 font-semibold text-base">
                Also post in social handles:
              </label>
              <div className="flex flex-col gap-1.5 mt-2 mb-5">
                {this.state.socialLinks?.map((item) => {
                  return item?.service_status === "SOCIAL" ? ( // Non-BLOG items for social links
                    <label
                      key={item?.uuid}
                      className="flex items-center gap-1.5"
                      htmlFor={item?.uuid}
                    >
                      <input
                        value={item?.uuid}
                        onChange={(e) => this.handleLinksChange(e.target.value, "social")}
                        type="checkbox"
                        id={item?.uuid}
                        checked={this.state.socialLinksUUID.includes(item?.uuid)} // Control the checked state
                      />
                      <span className="text-gray-800 text-sm">{item?.service_name}</span>
                      <span className="text-gray-500 text-xs">
                        ({item?.author_email})
                      </span>
                    </label>
                  ) : null; // Return null for BLOG items
                })}
              </div>
            </div>

            <div>
              <label className="text-gray-800 font-semibold text-base">
                SEO analysis:
              </label>
              <div className="flex flex-col gap-1.5 mt-2 mb-5">
                <div className="flex gap-2 items-center">
                  <svg
                    aria-hidden="true"
                    role="img"
                    focusable="false"
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 496 512"
                    fill="#008a00"
                  >
                    <path d="M248 8C111 8 0 119 0 256s111 248 248 248s248-111 248-248S385 8 248 8z M328 176c17.7 0 32 14.3 32 32 s-14.3 32-32 32s-32-14.3-32-32S310.3 176 328 176z M168 176c17.7 0 32 14.3 32 32s-14.3 32-32 32s-32-14.3-32-32S150.3 176 168 176 z M362.8 346.2C334.3 380.4 292.5 400 248 400s-86.3-19.6-114.8-53.8c-13.6-16.3 11-36.7 24.6-20.5c22.4 26.9 55.2 42.2 90.2 42.2 s67.8-15.4 90.2-42.2C351.6 309.5 376.3 329.9 362.8 346.2L362.8 346.2z"></path>
                  </svg>
                  <span className="text-gray-500 text-xs">
                    Readability:
                    <strong className="text-gray-800 text-sm ml-1.5">Good</strong>
                  </span>
                </div>
                <div className="flex gap-2 items-center">
                  <svg
                    aria-hidden="true"
                    role="img"
                    focusable="false"
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 496 512"
                    fill="#ee7c1b"
                  >
                    <path d="M248 8c137 0 248 111 248 248S385 504 248 504S0 393 0 256S111 8 248 8z M360 208c0-17.7-14.3-32-32-32 s-32 14.3-32 32s14.3 32 32 32S360 225.7 360 208z M344 368c21.2 0 21.2-32 0-32H152c-21.2 0-21.2 32 0 32H344z M200 208 c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32S200 225.7 200 208z"></path>
                  </svg>
                  <span className="text-gray-500 text-xs">
                    SEO:<strong className="text-gray-800 text-sm ml-1.5">OK</strong>
                  </span>
                </div>
                <div className="flex gap-2 items-center">
                  <svg
                    aria-hidden="true"
                    role="img"
                    focusable="false"
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 496 512"
                    fill="#dc3232"
                  >
                    <path d="M248 8C111 8 0 119 0 256s111 248 248 248s248-111 248-248S385 8 248 8z M328 176c17.7 0 32 14.3 32 32 s-14.3 32-32 32s-32-14.3-32-32S310.3 176 328 176z M168 176c17.7 0 32 14.3 32 32s-14.3 32-32 32s-32-14.3-32-32S150.3 176 168 176 z M338.2 394.2C315.8 367.4 282.9 352 248 352s-67.8 15.4-90.2 42.2c-13.5 16.3-38.1-4.2-24.6-20.5C161.7 339.6 203.6 320 248 320 s86.3 19.6 114.7 53.8C376.3 390 351.7 410.5 338.2 394.2L338.2 394.2z"></path>
                  </svg>
                  <span className="text-gray-500 text-xs">
                    Keywords:
                    <strong className="text-gray-800 text-sm ml-1.5">
                      Needs improvement
                    </strong>
                  </span>
                </div>
              </div>
              <div>
                <label className="text-gray-800 font-semibold text-base">
                  Required 7 improvements for better SEO:
                </label>
                <ul className="text-gray-500 text-xs flex flex-col gap-2.5 mt-2.5 max-h-56 overflow-y-scroll">
                  <li>
                    1.
                    <strong className="text-gray-800 ml-1 text-xs">Internal links</strong>
                    : No internal links appear in this page.
                  </li>
                  <li>
                    2.
                    <strong className="text-gray-800 ml-1 text-xs">
                      Keywords in introduction
                    </strong>
                    : Your keywords or its synonyms do not appear in the first paragraph.
                  </li>
                  <li>
                    3.
                    <strong className="text-gray-800 ml-1 text-xs">
                      Keywords density
                    </strong>
                    : The keywords was found 2 times. That's less than the recommended
                    minimum of 6 times for a text of this length.
                  </li>
                  <li>
                    4.
                    <strong className="text-gray-800 ml-1 text-xs">
                      Keywords in subheading
                    </strong>
                    : Use more keywordss or synonyms in your H2 and H3 subheadings!
                  </li>
                  <li>
                    5.
                    <strong className="text-gray-800 ml-1 text-xs">Image Keywords</strong>
                    : Images on this page do not have alt attributes that reflect the
                    topic of your text.
                  </li>
                  <li>
                    6.
                    <strong className="text-gray-800 ml-1 text-xs">
                      Meta description length
                    </strong>
                    : The meta description is too short (under 120 characters). Up to 156
                    characters are available.
                  </li>
                  <li>
                    7.
                    <strong className="text-gray-800 ml-1 text-xs">
                      Keywords in meta description
                    </strong>
                    : Sentences contain passive voice, which is not good for SEO.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="grid lg:grid-cols-2 gap-4 mt-4 mb-4">
          <div className="w-60 py-5 flex gap-4 items-center">
            <label className="text-gray-700 text-sm flex-shrink-0">Schedule Date:</label>
            <DatePicker
              type="button"
              title="Select Schedule Date"
              className="w-60 rounded-md border bg-white rounded px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 text-sm"
              selected={this.state.schedule_date}
              onChange={(date) => this.setState({ schedule_date: date })}
              isClearable
              placeholderText="Schedule Publish Date"
            />
          </div>

          <div className="py-5 flex justify-end">
            <button
              disabled={this.state.disable}
              type="submit"
              className={
                this.state.disable
                  ? "bg-gray-400 opacity-50 text-white font-bold py-0 px-24 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  : "bg-indigo-600 hover:bg-indigo-800 text-white font-bold py-0 px-24 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              }
              onClick={() => {
                this.handleSubmit();
              }}
            >
              Publish
            </button>
          </div>
        </div>

        <div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}
