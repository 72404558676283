import React, { Component } from "react";
import Loader from "react-loader-spinner";

import fetch from "../../configs/axios";

// Register Form
export default class RegisterForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstname: "",
      lastname: "",
      address: "",
      email: "",
      password: "",
      password2: "",
      error: "",
      loadingBar: false,
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  matchPassword = () => {
    if (this.state.password !== this.state.password2) {
      this.setState({
        error: "Password Did not matched",
      });
    } else if (this.state.password === this.state.password2) {
      this.setState({
        error: "",
      });
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.state.password !== this.state.password2) {
      this.setState({
        error: "Password Did not matched",
      });
      return;
    }

    const data = {
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      address: this.state.address,
      email: this.state.email,
      password: this.state.password,
    };

    try {
      const res = await fetch.post("/evident/auth/api/v1/user/register/", data);
      console.log(res);
      localStorage.setItem("userEmail", this.state.email);
      this.props.history.push("/register/confirm");
    } catch (error) {
      if (error.response) {
        this.setState({
          error: error.response.data.message,
        });
      } else {
        this.setState({
          error: "Something Unexpected occurs, Please Try Again",
        });
      }
    }
  };

  // async register(e) {
  //   await this.setState({
  //     loadingBar: true,
  //   });

  //   if (this.state.password !== this.state.password2) {
  //     await this.setState({
  //       error: "Password Did not matched",
  //     });

  //     return;
  //   }

  //   axios
  //     .post(process.env.REACT_APP_AUTH_BASE_URL + "/api/v1/auth/register", {
  //       firstname: this.state.firstname,
  //       lastname: this.state.lastname,
  //       address: this.state.address,
  //       email: this.state.email,
  //       password: this.state.password,
  //     })
  //     .then((res) => {
  //       console.log(res);
  //       this.props.history.push("/register/confirm", this.state.email);
  //     })
  //     .catch(
  //       function (error) {
  //         //handle error
  //         if (error.response.data) {
  //           this.setState({
  //             error: error.response.data.message,
  //           });
  //         } else {
  //           this.setState({
  //             error: "Something Unexpected occurs, Please Try Again",
  //           });
  //         }

  //         //console.log("called", error.response.data.message);
  //       }.bind(this)
  //     );

  //   await this.setState({
  //     loadingBar: false,
  //   });
  // }

  render() {
    return (
      <div>
        {this.state.loadingBar ? (
          <div className="min-h-screen flex items-center justify-center">
            <Loader
              type="MutatingDots"
              color="#00BFFF"
              height={80}
              width={80}
              visible={this.state.loadingBar}
            />
          </div>
        ) : (
          <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
              <div>
                <img className="mx-auto h-12 w-auto" src="/logo192.png" alt="Workflow" />
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                  Register Your Account
                </h2>
              </div>
              <form className="mt-8 space-y-6" onSubmit={this.handleSubmit}>
                <input type="hidden" name="remember" defaultValue="true" />
                <div className="rounded-md shadow-sm">
                  <div className="mb-3">
                    <label htmlFor="first-name" className="sr-only">
                      First name
                    </label>
                    <input
                      id="first-name"
                      name="firstname"
                      type="text"
                      autoComplete="off"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-400 focus:border-gray-400 focus:z-10 sm:text-sm"
                      placeholder="First name"
                      value={this.state.firstname}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="last-name" className="sr-only">
                      Last name
                    </label>
                    <input
                      id="last-name"
                      name="lastname"
                      type="text"
                      autoComplete="off"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-400 focus:border-gray-400 focus:z-10 sm:text-sm"
                      placeholder="Last name"
                      value={this.state.lastname}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="address" className="sr-only">
                      Address
                    </label>
                    <input
                      id="address"
                      name="address"
                      type="text"
                      autoComplete="off"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-400 focus:border-gray-400 focus:z-10 sm:text-sm"
                      placeholder="Address"
                      value={this.state.address}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="off"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-400 focus:border-gray-400 focus:z-10 sm:text-sm"
                      placeholder="Email address"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-gray-400 focus:border-gray-400 focus:z-10 sm:text-sm"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="password_confirm" className="sr-only">
                      Password
                    </label>
                    <input
                      id="password_confirm"
                      name="password2"
                      type="password"
                      autoComplete="new-password"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-400 focus:border-gray-400 focus:z-10 sm:text-sm"
                      placeholder="Confirm Password"
                      value={this.state.password2}
                      onChange={this.handleChange}
                    />
                  </div>

                  {this.state.error !== "" && (
                    <div className="flex items-center justify-between my-2">
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-red-500"
                      >
                        {this.state.error}
                      </label>
                    </div>
                  )}
                </div>

                <div>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white evident-bg focus:outline-none"
                  >
                    Sign up
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }
}
