import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
//import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import { convertToRaw, EditorState ,ContentState} from 'draft-js';
//import draftToHtml  from 'draftjs-to-html';
//import htmlToDraft from 'html-to-draftjs';

import axios from "axios";

import DOMPurify from "dompurify";

function BlogView(props) {
  const { slug } = useParams();

  const [blogData, setBlogData] = useState({});

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  useEffect(() => {
    axios.get(`https://api.evidentbd.com/blog/api/v1/public/blogs?slug=${slug}`).then((res) => {
      const { data } = res;
      setBlogData(data?.data[0]);
    });
  }, []);

  console.log(blogData);

  return (
    <div>
      <div className="grid lg:grid-cols-2 gap-4 mt-4">
        <nav className="bg-grey-light rounded font-sans w-full pl-1">
          <ol className="list-reset flex text-gray-500">
            <li>
              <Link to="/dashboard">
                <span className="hover:text-gray-800">Dashboard</span>
              </Link>
            </li>
            <span className="mx-2">/</span>
            <li>
              <Link to="/blogs">
                <span className="hover:text-gray-800">Blogs</span>
              </Link>
            </li>
            <span className="mx-2">/</span>
            <li>Article</li>
          </ol>
        </nav>
      </div>
      {/* <div className="container mx-auto">
                <div className="py-8">
                    <div className="grid grid-cols-1">
                        <h4 className="text-3xl font-bold leading-tight tracking-normal text-gray-900 text-center">Title : {Blog.title} - by {Blog.author}</h4>
                    </div>
                </div>
            </div> */}

      <div className="bg-gray-100 rounded-xl pt-4 p-8 my-8">
        <article className="xl:grid xl:grid-cols-4 xl:space-y-0 xl:items-baseline">
          <dl className="xl:text-left text-center">
            <dt className="text-sm font-small text-gray-400">Channel :</dt>
            <dd className="text-base font-medium text-gray-600 pb-3">{blogData?.channels?.name}</dd>

            <dt className="text-sm font-small text-gray-400">Domain :</dt>
            <dd className="text-base font-medium text-gray-600 pb-3">
              {blogData?.channels?.domain}
            </dd>

            <dt className="text-sm font-small text-gray-400">Author :</dt>
            <dd className="text-base font-medium text-gray-600 pb-3">{blogData?.author}</dd>

            <dt className="text-sm font-small text-gray-400">Published on :</dt>
            <dd className="text-base font-medium text-gray-600 pb-3">{blogData?.created_at}</dd>

            <dt className="text-sm font-small text-gray-400">Meta Description :</dt>
            <dd className="text-base font-medium text-gray-600 pb-3">
              {blogData?.meta_description}
            </dd>

            <dt className="text-sm font-small text-gray-400">Meta Keywords :</dt>
            <dd className="text-base font-medium text-gray-600 pb-3">{blogData?.meta_keyword}</dd>

            <dt className="text-sm font-small text-gray-400">Resource Tag :</dt>
            <dd className="text-base font-medium text-gray-600 pb-3">{blogData?.tag}</dd>
          </dl>
          <div className="space-y-6 xl:col-span-3">
            <div className="space-y-6 card bg-white p-8 m-2 rounded-xl">
              <h1 className="text-3xl font-bold leading-tight tracking-normal text-gray-900 text-center">
                {blogData?.title}
              </h1>
              <div className="prose max-w-none text-gray-700">
                <div
                  className="preview"
                  dangerouslySetInnerHTML={createMarkup(blogData?.content)}
                ></div>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
}

export default BlogView;
