import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";

function Dashboard() {
  let history = useHistory();
  const [allChannels, setAllChannels] = useState([]);
  const [totalChannels, settotalChannels] = useState([]);
  const [allBlogs, setAllBlogs] = useState([]);
  const [user, setUser] = useState([]);
  //const [colorClass, setColorClass] = useState("red");
  const [totalBlogs, setTotalBlogs] = useState("");

  useEffect(() => {
    //console.log(process.env.REACT_APP_BLOG_API_BASE_URL)

    axios
      .get(
        process.env.REACT_APP_BLOG_API_BASE_URL +
          "/blog/api/v1/admin/paginate/channels?page=1&records=5",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        //console.log(Math.ceil(res.data.count/6))
        settotalChannels(res.data.data.count);

        setAllChannels(res.data.data.results);
        //console.log("ca", totalChannels, allChannels, res.data.results)
      })
      .catch(function (error) {
        // handle error
        if (error.response) {
          authorizationCheck(error.response.status);
        }
      });

    axios
      .get(process.env.REACT_APP_BLOG_API_BASE_URL + "/blog/api/v1/admin/blogs?page=1&records=4", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        //console.log(Math.ceil(res.data.count/6))

        setAllBlogs(res.data.data.results);
        setTotalBlogs(res.data.data.count);
        //console.log("ca", totalChannels, allChannels, res.data.results)
      })
      .catch(function (error) {
        // handle error
        if (error.response) {
          authorizationCheck(error.response.status);
        }
      });

    axios
      .get(process.env.REACT_APP_AUTH_BASE_URL + "/api/v1/auth/validate-token/", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        //console.log(Math.ceil(res.data.count/6))

        setUser(res.data.data.user);
        //console.log("ca", res.data.data.user)
      })
      .catch(function (error) {
        // handle error
        if (error.response) {
          authorizationCheck(error.response.status);
        }
      });
  }, []);

  function authorizationCheck(status) {
    if (status === 401) {
      axios
        .post(
          process.env.REACT_APP_AUTH_BASE_URL + "/api/v1/auth/refresh-token/",
          {
            refresh_token: localStorage.getItem("refresh_token"),
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          //console.log(res.data.data.access_token)
          localStorage.setItem("token", res.data.data.access_token);
          localStorage.setItem("refresh_token", res.data.data.refresh_token);
        })
        .catch(function (error) {
          // handle error
          localStorage.clear();
          history.push("/");
        });
    }
  }

  return (
    <div>
      <div className="grid lg:grid-cols-2 gap-4 mt-4">
        <nav className="bg-grey-light rounded font-sans w-full pl-1">
          <ol className="list-reset flex text-gray-500">
            <li>Dashboard</li>
          </ol>
        </nav>
      </div>

      <div className="grid lg:grid-cols-2 gap-4 mt-5 mx-4">
        <div className="p-6 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-white">
          <div className="flex items-start justify-between">
            <div className="flex flex-col space-y-2">
              <span className="text-gray-400">Total Blogs</span>
              <span className="text-2xl font-semibold">{totalBlogs}</span>
            </div>
            <div className="p-4 bg-red-500 rounded-md block flex flex-row justify-center items-center text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-compass"
                width={32}
                height={32}
                viewBox="0 0 24 24"
                strokeWidth="3"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <polyline points="8 16 10 10 16 8 14 14 8 16" />
                <circle cx={12} cy={12} r={9} />
              </svg>
            </div>
          </div>
          <div>
            {/* <span className="inline-block px-2 text-sm text-white bg-green-300 rounded">14%</span> */}
            {/* <span>from 2021</span> */}
          </div>
        </div>

        <div className="p-6 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-white">
          <div className="flex items-start justify-between">
            <div className="flex flex-col space-y-2">
              <span className="text-gray-400">Total Channels</span>
              <span className="text-2xl font-semibold">{totalChannels}</span>
            </div>
            <div className="p-3 bg-blue-500 rounded-md block flex flex-row justify-center items-center text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
              </svg>
            </div>
          </div>
          <div>
            {/* <span className="inline-block px-2 text-sm text-white bg-green-300 rounded">14%</span> */}
            {/* <span>from 2021</span> */}
          </div>
        </div>

        {/* <div className="p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg  bg-white">
                    <div className="flex items-start justify-between">
                        <div className="flex flex-col space-y-2">
                            <span className="text-gray-400">Total Users</span>
                            <span className="text-lg font-semibold">100,221</span>
                        </div>
                        <div className="p-10 bg-gray-200 rounded-md"></div>
                    </div>
                    <div>
                        <span>from 2021</span>
                    </div>
                </div> */}
      </div>

      {/* <h3 className="mt-6 text-xl">Users</h3> */}
      <div className="flex flex-col mt-6 mx-4">
        <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden border-b border-gray-200 rounded-md shadow-md">
              <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      Address
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                    >
                      Role
                    </th>
                    {/* <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            Acton
                                        </th> */}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr className="transition-all hover:bg-gray-100 hover:shadow-lg">
                    <td className="px-6 py-4 whitespace-nowrap">
                      {/* <div className="flex items-center">
                                                <div className="ml-4"> */}
                      <div className="text-sm font-medium text-gray-900">
                        {user.firstname} {user.lastname}
                      </div>
                      {/* <div className="text-sm text-gray-500">{user.email}</div>
                                                </div>
                                            </div> */}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {/* <div className="flex items-center">
                                                <div className="ml-4">
                                                    <div className="text-sm font-medium text-gray-900">{user.firstname} {user.lastname}</div> */}
                      <div className="text-sm text-gray-500">{user.email}</div>
                      {/* </div>
                                            </div> */}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{user.address}</div>
                      {/* <div className="text-sm text-gray-500">Optimization</div> */}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                        Active
                      </span>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">Admin</td>
                    {/* <td className="px-6 py-4 text-sm font-medium  whitespace-nowrap">
                                            <a href="#" className="text-indigo-600 hover:text-indigo-900">Edit</a>
                                        </td> */}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap flex-row">
        <div className="w-full lg:w-1/2">
          <section className="m-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-white">
            <Link to="/blogs">
              <header className="flex justify-between border-b border-solid border-gray-300 p-4 text-lg font-medium text-gray-800">
                <span>Recent Blogs</span>
                <span className="text-2xl text-red-700">&#8608;</span>
              </header>
            </Link>
            <section className="overflow-x-auto w-full">
              <table className="w-full" cellPadding="0" cellSpacing="0" border="0">
                <tbody>
                  {allBlogs.map((blog, i) => {
                    console.log(blog);
                    return (
                      <tr key={i}>
                        <td className="p-2 py-4 border-b border-solid border-gray-300">
                          <Link
                            to={{
                              pathname: `/blogs/view/${blog.slug}`,
                              data: { blog },
                            }}
                          >
                            <div className="pl-4 flex flex-nowrap items-center">
                              <div className="mr-4 p-4 h-8 w-8 bg-red-600 rounded-full block flex flex-row justify-center items-center text-white">
                                {blog.title[0].toUpperCase()}
                              </div>
                              <div className="text-gray-700">{blog.title}</div>
                            </div>
                          </Link>
                        </td>
                        {/* <td className="text-right p-2 pr-4 border-b border-solid border-gray-300 text-gray-700">$35,210.66</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </section>
          </section>
        </div>
        <div className="w-full lg:w-1/2">
          <section className="m-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg bg-white">
            <Link to="/channels">
              <header className="flex justify-between border-b border-solid border-gray-300 p-4 text-lg font-medium text-gray-800">
                <span>Recent Channels</span>
                <span className="text-2xl text-blue-700">&#8608;</span>
              </header>
            </Link>
            <section className="overflow-x-auto w-full">
              <table className="w-full" cellPadding="0" cellSpacing="0" border="0">
                <tbody>
                  {allChannels.map((channel, i) => (
                    <tr key={i}>
                      <td className="p-2 py-4 border-b border-solid border-gray-300">
                        <div className="pl-4 flex flex-nowrap items-center">
                          <div className="mr-4 p-4 h-8 w-8 bg-blue-600 rounded-full block flex flex-row justify-center items-center text-white">
                            {channel.name[0].toUpperCase()}
                          </div>
                          <div className="text-gray-700">{channel.name}</div>
                          {/* <div className="text-gray-700">{channel.name.toUpperCase()}</div> */}
                        </div>
                      </td>
                      {/* <td className="text-right p-2 pr-4 border-b border-solid border-gray-300 text-gray-700">{channel.domain}</td> */}
                    </tr>
                  ))}

                  {/* <tr>
                                        <td className="p-2 py-4 border-b border-solid border-gray-300">
                                            <div className="pl-4 flex flex-wrap flex-row items-center">
                                                <div className="mr-4 h-12 w-12 bg-yellow-600 rounded-full block flex  flex-row justify-center items-center text-white">G</div>
                                                <div className="text-gray-700">Google London</div>
                                            </div>

                                        </td>
                                        <td className="text-right p-2 pr-4 border-b border-solid border-gray-300 text-gray-700">$9,586.11</td>
                                    </tr> */}
                </tbody>
              </table>
            </section>
          </section>
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
