
import React, { Component } from 'react';



 class ForgetPassword extends Component {

  constructor(props){
    super()
  }

 render() {
  return (
    <div>
    
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="/logo192.png"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Enter your recovery email</h2>
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm">
            <div className="mb-3">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="off"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-400 focus:border-gray-400 focus:z-10 sm:text-sm"
                placeholder="Email address"
              />
            </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white evident-bg focus:outline-none"
            >
              {/* <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span> */}
              Send
            </button>
          </div>
          </div>
        </form>
      </div>
    </div>
   </div> 
  )
}
}

export default ForgetPassword;

// function ForgetPassword() {

//   return(
     
//       <div>
//           <h1>Hello</h1>

         

//       </div>
 
//   );

// }
// export default ForgetPassword;