import "./App.css";
import "draft-js/dist/Draft.css";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import LoginForm from "./component/landing/loginform";
import RegisterForm from "./component/landing/register";
import ConfirmRegistration from "./component/landing/confirmRegistration";
import ForgetPassword from "./component/landing/forgetpassword";
import Dashboard from "./component/dashboard/dashboard";
import Channels from "./component/channels/index.channels";
import Blogs from "./component/blogs/index.blogs";
// Layouts

import DashboardLayout from "./component/layouts/dashboardLayout";
import LandingLayout from "./component/layouts/lanbdingLayout";
import BlogView from "./component/blogs/blogView";
import BlogCreate from "./component/blogs/blogCreate";
import BlogEdit from "./component/blogs/blogEdit";
import { ToastContainer } from "react-toastify";

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  let isAuthenticated = false;

  if (localStorage.getItem("isAuthenticated") === "true") {
    isAuthenticated = true;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? (
          <Layout>
            <Component {...props}></Component>
          </Layout>
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    ></Route>
  );
};

function ProtectedRoute({ component: Component, layout: Layout, ...rest }) {
  let isAuthenticated = false;

  if (localStorage.getItem("isAuthenticated") === "true") {
    isAuthenticated = true;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Layout>
            <Component {...props}></Component>
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    ></Route>
  );
}

function App() {
  return (
    <Router>
      <div className="">
        <Switch>
          <AppRoute path="/" exact layout={LandingLayout} component={LoginForm} />
          <AppRoute path="/register" exact layout={LandingLayout} component={RegisterForm} />
          <AppRoute path="/login" exact layout={LandingLayout} component={LoginForm} />
          <AppRoute
            path="/forget-password"
            exact
            layout={LandingLayout}
            component={ForgetPassword}
          />
          <AppRoute
            path="/register/confirm"
            exact
            layout={LandingLayout}
            component={ConfirmRegistration}
          />

          <ProtectedRoute path="/dashboard" exact layout={DashboardLayout} component={Dashboard} />
          <ProtectedRoute path="/channels" exact layout={DashboardLayout} component={Channels} />
          <ProtectedRoute path="/blogs" exact layout={DashboardLayout} component={Blogs} />
          <ProtectedRoute
            path="/blogs/view/:slug"
            exact
            layout={DashboardLayout}
            component={BlogView}
          />
          <ProtectedRoute
            path="/blogs/edit/:slug"
            exact
            layout={DashboardLayout}
            component={BlogEdit}
          />
          <ProtectedRoute
            path="/blogs/create"
            exact
            layout={DashboardLayout}
            component={BlogCreate}
          />
        </Switch>
      </div>
      <ToastContainer />
    </Router>
  );
}

export default App;
