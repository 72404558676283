import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Blogs() {
  let history = useHistory();

  //const [state, setState] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [allChannels, setAllChannels] = useState([]);
  const [selectedChannel, setselectedChannel] = useState("all");
  const [loadingBar, setLoadingBar] = useState(false);
  const [totalPages, setTotalPage] = useState(0);
  const [currentpage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [deleteBlogId, setdeleteBlogId] = useState("");
  const [totalBlogs, setTotalBlogs] = useState("");

  const token = localStorage.getItem("token");

  useEffect(() => {
    setCurrentPage(1);

    axios
      .get(process.env.REACT_APP_BLOG_API_BASE_URL + "/blog/api/v1/admin/blogs?page=1&records=10", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setTotalBlogs(res.data.data.count);
        setTotalPage(Math.ceil(res.data.data.count / 10));
        setSearchResults(res.data.data.results);
      })
      .catch(function (error) {
        // handle error
        //console.log(error);
        if (error.response) {
          // authorizationCheck(error.response.status);
        }
      });

    //console.log(totalPages)

    getAllChannels();
    setLoadingBar(false);
  }, []);

  function getAllChannels() {
    axios
      .get(process.env.REACT_APP_BLOG_API_BASE_URL + "/blog/api/v1/admin/channels", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        //console.log(res.data)
        setAllChannels(res.data.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        if (error.response) {
          // authorizationCheck(error.response.status);
        }
      });
  }

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectBox = (event) => {
    setselectedChannel(event.target.value);
  };

  function authorizationCheck(status) {
    if (status === 401) {
      axios
        .post(
          process.env.REACT_APP_AUTH_BASE_URL + "/api/v1/auth/refresh-token/",
          {
            refresh_token: localStorage.getItem("refresh_token"),
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          //console.log(res.data.data.access_token)
          localStorage.setItem("token", res.data.data.access_token);
          localStorage.setItem("refresh_token", res.data.data.refresh_token);
        })
        .catch(function (error) {
          // handle error
          localStorage.clear();
          history.push("/");
        });
    }
  }

  function fetchAllBlog(page) {
    //console.log("called")

    setCurrentPage(page);

    axios
      .get(
        process.env.REACT_APP_BLOG_API_BASE_URL +
          "/blog/api/v1/admin/blogs?search=" +
          searchTerm +
          "&service=" +
          selectedChannel +
          "&page=" +
          page +
          "&records=10",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        //console.log("Called", res.data.results, searchTerm, selectedChannel, currentpage)
        setTotalBlogs(res.data.data.count);
        setTotalPage(Math.ceil(res.data.data.count / 10));
        setSearchResults(res.data.data.results);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        if (error.response) {
          authorizationCheck(error.response.status);
        }
      });

    setLoadingBar(false);
  }

  function deleteBlog(id) {
    //console.warn(id,"called")

    axios
      .delete(process.env.REACT_APP_BLOG_API_BASE_URL + "/blog/api/v1/admin/update_blog/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        fetchAllBlog(currentpage);
        //console.log(res)
      })
      .catch(function (error) {
        //console.log(error);
        authorizationCheck(error.response.status);
      });

    hideModalView();
    notifyDelete();
  }

  useEffect(() => {
    fetchAllBlog(1);
  }, [selectedChannel]);

  useEffect(() => {
    setLoadingBar(true);
  }, [currentpage]);

  useEffect(() => {
    setLoadingBar(false);
  }, [searchResults]);

  function showModalView(id) {
    setShowModal(true);
    setdeleteBlogId(id);
  }

  function hideModalView() {
    setShowModal(false);
    setdeleteBlogId(0);
  }

  const notifyDelete = () => toast("Blog Deleted Successfully!");
  // const notifyAdd = () => toast("Blog Added Successfully!");
  // const notifyUpdate = () => toast("Blog Updated Successfully!");

  //console.log(searchResults);

  return (
    <div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-1xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <button
                    className="mr-5 ml-auto bg-transparent border-0 text-black opacity-9 float-right text-md leading-none font-semibold outline-none focus:outline-none"
                    onClick={hideModalView}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-pink-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        {/* <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                                    Deactivate account
                                                </Dialog.Title> */}
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Delete Blog</h3>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">Are you sure to delete this Blog?</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 sm:px-6 sm:flex justify-center sm:flex-row-reverse">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-700 text-base font-medium text-white hover:bg-pink-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-800 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={(e) => deleteBlog(deleteBlogId, e)}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={hideModalView}
                      //ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      <div className="grid lg:grid-cols-2 gap-4 mt-4">
        <nav className="bg-grey-light rounded font-sans w-full pl-1">
          <ol className="list-reset flex text-gray-500">
            <li>
              <Link to="/dashboard">
                <span className="hover:text-gray-800">Dashboard</span>
              </Link>
            </li>
            <span className="mx-2">/</span>
            <li>Blogs</li>
          </ol>
        </nav>
      </div>

      <div className="container mx-auto">
        <div className="py-8">
          <div className="grid grid-cols-2">
            <h4 className="text-xl font-bold leading-tight flex items-center">All Blogs</h4>

            <div className="flex justify-end">
              <Link
                to={{
                  pathname: "/blogs/create",
                  data: "",
                }}
              >
                <button
                  className="group relative flex inline-flex items-center justify-center w-10 h-10 mr-2 py-2 border-transparent text-sm font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  type="button"
                >
                  <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                    <path
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clipRule="evenodd"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </Link>
            </div>
          </div>

          <div className="container grid lg:grid-cols-2 gap-8 my-8">
            <div className="relative text-gray-600 focus-within:text-gray-400">
              <input
                className="w-full py-2 text-sm text-white rounded-md pl-5 focus:outline-none focus:bg-white text-gray-900"
                placeholder="Search With Title"
                value={searchTerm}
                onChange={handleChange}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    fetchAllBlog(1);
                  }
                }}
              />
              <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                <button
                  onClick={() => fetchAllBlog(1)}
                  type="submit"
                  className="p-1 focus:outline-none focus:shadow-outline"
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    className="w-6 h-6"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </button>
              </span>
            </div>

            <select
              className="w-full rounded-md border bg-white rounded px-3 py-2 outline-none text-gray-900 text-sm"
              value={selectedChannel}
              onChange={handleSelectBox}
            >
              <option className="py-1" value="all">
                Select Channel
              </option>
              {allChannels.map(({ name, id }, index) => (
                <option className="py-1" key={index} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>

          <div>
            {/* {searchResults.map(product =>

                    <h1 key={product.id}>{product.title}</h1>
                )} */}

            {/* <Link to={{
                                pathname: '/blogs/create',
                                data : '',

                            }}>
                                <button
                                    type="submit"
                                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Create
                                </button>
                            </Link> */}
          </div>

          <div className="flex flex-col mt-6 my-2">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              {loadingBar ? (
                <div className="flex items-center justify-center bg-white">
                  <Loader
                    type="MutatingDots"
                    color="#00BFFF"
                    height={80}
                    width={80}
                    visible={loadingBar}
                  />
                </div>
              ) : (
                <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="pl-4 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                      >
                        Sl
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                      >
                        Title
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                      >
                        Author
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                      >
                        Channel Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {searchResults.map((blog, i) => (
                      <tr className="transition-all hover:bg-gray-100 hover:shadow-lg" key={i}>
                        <td className="pl-4 py-5 whitespace-nowrap">
                          <p className="text-sm font-medium text-gray-900">
                            {i + (currentpage - 1) * 10 + 1}
                          </p>
                        </td>
                        <td className="px-2 py-5 whitespace-nowrap">
                          <p className="text-sm font-medium text-gray-900">{blog.title}</p>
                        </td>

                        <td className="px-3 py-5 whitespace-nowrap">
                          <p className="text-sm font-medium text-gray-900">{blog.author}</p>
                        </td>
                        <td className="px-3 py-5 whitespace-nowrap">
                          <p className="text-sm font-medium text-gray-900">{blog.channels?.name}</p>
                        </td>
                        <td className="flex items-center px-3 py-5 whitespace-nowrap">
                          <Link to={`/blogs/view/${blog.slug}`}>
                            <button
                              type="submit"
                              className="flex items-center h-5 px-2 mr-2 text-sm text-blue-100 transition-colors duration-150 bg-blue-700 rounded-lg focus:shadow-outline hover:bg-blue-800"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                />
                              </svg>
                            </button>
                          </Link>

                          <Link to={`/blogs/edit/${blog.slug}`}>
                            <button
                              type="submit"
                              className="flex items-center h-5 px-2 mr-2 text-sm text-purple-100 transition-colors duration-150 bg-purple-700 rounded-lg focus:shadow-outline hover:bg-purple-800"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                />
                              </svg>
                            </button>
                          </Link>

                          <button
                            className="inline-flex items-center h-5 px-2 text-sm text-pink-100 transition-colors duration-150 bg-pink-700 rounded-lg focus:shadow-outline hover:bg-pink-800"
                            type="button"
                            onClick={(e) => showModalView(blog.id)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                          </button>

                          {/* <span

                                                        className="flex items-center h-5 px-2 mr-2 text-sm text-pink-100 transition-colors duration-150 bg-pink-700 rounded-lg focus:shadow-outline hover:bg-pink-800"
                                                    >
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                            </svg>
                                                    </span> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                <div className="flex-1 flex justify-between sm:hidden">
                  <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                    Previous
                  </span>
                  <span className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                    Next
                  </span>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing <span className="font-medium">{currentpage * 10 - 9}</span> to{" "}
                      <span className="font-medium">
                        {currentpage === totalPages ? totalBlogs : currentpage * 10}
                      </span>{" "}
                      of <span className="font-medium">{totalBlogs}</span> results
                    </p>
                  </div>
                  <div>
                    <nav
                      className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                      aria-label="Pagination"
                    >
                      {currentpage > 1 && (
                        <span
                          type="button"
                          onClick={(e) => fetchAllBlog(currentpage - 1)}
                          className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                          <span className="sr-only">Previous</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      )}
                      {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                      {currentpage - 3 > 0 && (
                        <span className="cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                          ...
                        </span>
                      )}

                      {currentpage - 2 > 0 && (
                        <span
                          type="button"
                          onClick={(e) => fetchAllBlog(currentpage - 1)}
                          className="cursor-pointer bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                        >
                          {currentpage - 2}
                        </span>
                      )}

                      {currentpage - 1 > 0 && (
                        <span
                          type="button"
                          onClick={(e) => fetchAllBlog(currentpage - 1)}
                          className="cursor-pointer bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                        >
                          {currentpage - 1}
                        </span>
                      )}

                      <span
                        aria-current="page"
                        className="cursor-pointer z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                      >
                        {currentpage}
                      </span>
                      {totalPages - currentpage >= 1 && (
                        <span
                          type="button"
                          onClick={(e) => fetchAllBlog(currentpage + 1)}
                          className="cursor-pointer bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                        >
                          {currentpage + 1}
                        </span>
                      )}
                      {totalPages - currentpage >= 2 && (
                        <span
                          type="button"
                          onClick={(e) => fetchAllBlog(currentpage + 1)}
                          className="bg-white cursor-pointer bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                        >
                          {currentpage + 2}
                        </span>
                      )}

                      {totalPages - currentpage >= 3 && (
                        <span className="cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                          ...
                        </span>
                      )}

                      {/* <span
                                                
                                                className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                                                >
                                                8
                                                </span>
                                                <span
                                                
                                                className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                                >
                                                9
                                                </span>
                                                <span
                                                
                                                className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                                >
                                                10
                                                </span> */}
                      {currentpage < totalPages && (
                        <span
                          type="button"
                          onClick={(e) => fetchAllBlog(currentpage + 1)}
                          className="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                          <span className="sr-only">Next</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </span>
                      )}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ToastContainer />
      </div>
    </div>
  );
}
export default Blogs;
