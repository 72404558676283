import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { Link } from 'react-router-dom';

// import Button from "@material-tailwind/react/Button";

import { blogFetch } from "../../configs/axios";

function Channels() {
  let history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [showModalView, setViewModal] = useState(false);
  const [showModalEdit, setEditModal] = useState(false);
  const [showModalDelete, setDeleteModal] = useState(false);
  const [allChannels, setAllChannels] = useState([]);
  const [channelId, setChannelId] = useState("");
  const [channelNname, setChannelName] = useState("");
  const [domainAddress, setDomainAddress] = useState("");
  const [channelStatus, setChannelStatus] = useState(true);
  const [nameError, setNameError] = useState("");
  const [domainerror, setDomainError] = useState("");
  const [singleChannel, setSingleChannel] = useState({}); //view single channel
  const [totalPages, setTotalPage] = useState("");
  const [currentpage, setCurrentPage] = useState("");
  const [loadingBar, setLoadingBar] = useState(false);
  const [deleteChannelId, setdeleteChannelId] = useState("");
  const [totalChannels, setTotalChannels] = useState("");

  useEffect(() => {
    setLoadingBar(true);
    setCurrentPage(1);
    blogFetch
      .get("/blog/api/v1/admin/paginate/channels?page=1&records=5")
      .then(({ data }) => {
        setTotalChannels(data.data.count);
        setTotalPage(Math.ceil(data.data.count / 5));
        setAllChannels(data.data.results);
      })
      .catch(function (error) {
        // handle error
        // authorizationCheck(error.response?.status);
      });

    setLoadingBar(false);
  }, []);

  function fetchAllChannels(page) {
    if (page < 1 || page > totalPages) return;

    setCurrentPage(page);

    blogFetch
      .get("/blog/api/v1/admin/paginate/channels?page=" + page + "&records=5")
      .then((res) => {
        console.log(res.data);
        setAllChannels(res.data.data.results);
      })
      .catch(function (error) {
        // handle error
        //console.log(error);
        // authorizationCheck(error.response.status);
      });
  }

  function handleChannelChange(e) {
    setChannelName(e.target.value);
  }

  function handleDomainChange(e) {
    setDomainAddress(e.target.value);
  }

  function handleStatusChange(e) {
    setChannelStatus(!channelStatus);
  }

  async function checkAPI() {
    try {
      const { data } = await axios.get(
        "https://api.evidentbd.com/blog/api/v1/admin/channels",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      console.log(data);
    } catch (error) {
      console.log(error);
    }
  }

  function handleSubmit() {
    axios
      .post(
        process.env.REACT_APP_BLOG_API_BASE_URL + "/blog/api/v1/admin/channels",
        {
          name: channelNname,
          domain: domainAddress,
          status: "True",
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        fetchAllChannels(currentpage);
        clearAll();
        setShowModal(false);
        notifyAdd();
      })
      .catch(function (error) {
        // handle error
        setNameError(error.response?.data.name);
        setDomainError(error.response?.data.domain);
        authorizationCheck(error.response?.status);
        //console.log(nameError);
      });
  }

  function handleUpdate() {
    axios
      .put(
        process.env.REACT_APP_BLOG_API_BASE_URL +
          "/blog/api/v1/admin/update_channel/" +
          channelId,
        {
          name: channelNname,
          domain: domainAddress,
          status: channelStatus,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        fetchAllChannels(currentpage);
        clearAll();
        setEditModal(false);
        notifyUpdate();
      })
      .catch(function (error) {
        // handle error
        setNameError(error.response.data.name);
        setDomainError(error.response.data.domain);
        authorizationCheck(error.response.status);
      });
  }

  function viewBlog(channel) {
    setSingleChannel(channel);
    setViewModal(true);
  }

  function deleteChannel(id) {
    //console.warn(id,"called")
    setLoadingBar(true);
    axios
      .delete(
        process.env.REACT_APP_BLOG_API_BASE_URL +
          "/blog/api/v1/admin/update_channel/" +
          id,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        fetchAllChannels(currentpage);
        notifyDelete();
        //console.log(res)
      })
      .catch(function (error) {
        //console.log(error);
        authorizationCheck(error.response.status);
      });
    setLoadingBar(false);
    hideDeleteModalView();
  }

  function showDeleteModalView(id) {
    setDeleteModal(true);
    setdeleteChannelId(id);
  }

  function hideDeleteModalView() {
    setDeleteModal(false);
    setdeleteChannelId(0);
  }

  function clearAll() {
    setChannelName("");
    setDomainAddress("");
    setNameError("");
    setDomainError("");
  }

  function authorizationCheck(status) {
    if (status === 401) {
      axios
        .post(
          process.env.REACT_APP_AUTH_BASE_URL + "/api/v1/auth/refresh-token",
          {
            refresh_token: localStorage.getItem("refresh_token"),
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          //console.log(res.data.data.access_token)
          localStorage.setItem("token", res.data.data.access_token);
          localStorage.setItem("refresh_token", res.data.data.refresh_token);
        })
        .catch(function (error) {
          // handle error
          localStorage.clear();
          history.push("/");
        });
    }
  }

  const notifyDelete = () => toast.error("Channel Deleted Successfully!");
  const notifyAdd = () => toast("Channel Added Successfully!");
  const notifyUpdate = () => toast("Channel Updated Successfully!");

  return (
    <div>
      <div className="grid lg:grid-cols-2 gap-4 mt-4">
        <nav className="bg-grey-light rounded font-sans w-full pl-1">
          <ol className="list-reset flex text-gray-500">
            <li>
              <Link to="/dashboard">
                <span className="hover:text-gray-800">Dashboard</span>
              </Link>
            </li>
            <span className="mx-2">/</span>
            <li>Channels</li>
          </ol>
        </nav>
      </div>

      {showModalDelete ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-1xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <button
                    className="mr-5 ml-auto bg-transparent border-0 text-black opacity-9 float-right text-md leading-none font-semibold outline-none focus:outline-none"
                    onClick={hideDeleteModalView}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-pink-100 sm:mx-0 sm:h-10 sm:w-10">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Delete Channel
                        </h3>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure to delete this Channel?
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 sm:px-6 sm:flex justify-center sm:flex-row-reverse">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-700 text-base font-medium text-white hover:bg-pink-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-800 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={(e) => deleteChannel(deleteChannelId)}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={hideDeleteModalView}
                      //ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showModalView ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-1xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h5 className="text-md font-semibold">Channel Details</h5>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-9 float-right text-md leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setViewModal(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <form className="px-10 space-y-6 w-full">
                  <div>
                    <div className="transition-all">
                      <div className="text-sm font-bold leading-6 font-medium text-gray-900 mt-3">
                        Name:{" "}
                      </div>
                      <div className="text-sm font-medium text-gray-900">
                        {singleChannel.name}
                      </div>

                      <div className="text-sm font-bold leading-6 font-medium text-gray-900 mt-3">
                        Domain:{" "}
                      </div>
                      <div className="text-sm font-medium text-gray-900">
                        {singleChannel.domain}
                      </div>

                      {/* <div className="text-sm text-blue-900">{}</div> */}

                      <div className="whitespace-nowrap mt-3">
                        <span className="text-sm font-bold leading-6 font-medium text-gray-900">
                          Status:{" "}
                        </span>
                        {singleChannel.status ? (
                          <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                            Active
                          </span>
                        ) : (
                          <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-red-100 rounded-full">
                            Deactive
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center justify-center p-6 rounded-b">
                    <button
                      className="bg-pink-500 items-center text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setViewModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showModalEdit ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-1xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h5 className="text-md font-semibold">Edit Channel</h5>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-9 float-right text-md leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {
                      setEditModal(false);
                      clearAll();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <form className="px-10 space-y-6 w-full">
                  <input type="hidden" name="remember" defaultValue="true" />
                  <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                      <label className="text-sm font-bold leading-6 font-medium text-gray-900 mt-3">
                        Channel Name:
                      </label>
                      <input
                        id="channel-name"
                        name="channel-name"
                        type="text"
                        autoComplete="off"
                        className="appearance-none rounded-none relative block w-full px-3 py-2 mb-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Channel"
                        onChange={handleChannelChange}
                        value={channelNname}
                        required
                      />
                      {nameError ? (
                        <label className="text-red-500 text-sm">{nameError}</label>
                      ) : (
                        <label className="text-black text-sm"></label>
                      )}
                    </div>
                    <div>
                      <label className="text-sm font-bold leading-6 font-medium text-gray-900 mt-3">
                        Domain Address:
                      </label>
                      <input
                        id="domain-address"
                        name="domain-address"
                        type="url"
                        autoComplete="off"
                        className="appearance-none rounded-none relative block w-full px-3 py-2 mb-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="https://"
                        onChange={handleDomainChange}
                        value={domainAddress}
                        required
                      />
                      {domainerror ? (
                        <label className="text-red-500 text-sm">{domainerror}</label>
                      ) : (
                        <label className="text-black text-sm"></label>
                      )}
                    </div>
                    <div>
                      <label className="text-sm font-bold leading-6 font-medium text-gray-900 mt-3">
                        Status
                      </label>
                      <div>
                        <input
                          type="radio"
                          className="form-radio text-green-500 ml-4"
                          name="radio-colors"
                          value="1"
                          checked={channelStatus}
                          onChange={handleStatusChange}
                        />
                        <span className="inline-flex px-2 text-sm font-semibold leading-5 text-green-800 bg-green-100 rounded-full ml-1">
                          Active
                        </span>

                        <input
                          type="radio"
                          className="form-radio text-pink-600 ml-4"
                          name="radio-colors"
                          value="0"
                          checked={!channelStatus}
                          onChange={handleStatusChange}
                        />
                        <span className="inline-flex px-2 text-sm font-semibold leading-5 text-green-800 bg-red-100 rounded-full ml-1">
                          Deactive
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                                        <button
                                            type="submit"
                                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">

                                            </span>
                                            Sign in
                                        </button>
                                    </div> */}

                  {/*footer*/}
                  <div className="flex items-center justify-end p-6  rounded-b">
                    <button
                      className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        handleUpdate();
                      }}
                    >
                      Update
                    </button>
                    <button
                      className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setEditModal(false);
                        clearAll();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-1xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-center justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h5 className="text-md font-semibold">Add New Channel</h5>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-9 float-right text-md leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {
                      setShowModal(false);
                      clearAll();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                {/*body*/}
                <form className="px-10 space-y-6 w-full">
                  <input type="hidden" name="remember" defaultValue="true" />
                  <div className="rounded-md shadow-sm -space-y-px">
                    <div>
                      <label className="text-black text-sm">Channel Name</label>
                      <input
                        id="channel-name"
                        name="channel-name"
                        type="text"
                        autoComplete="off"
                        className="appearance-none rounded-none relative block w-full px-3 py-2 mb-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="Channel"
                        onChange={handleChannelChange}
                        value={channelNname}
                        required
                      />
                      {nameError ? (
                        <label className="text-red-500 text-sm">{nameError}</label>
                      ) : (
                        <label className="text-black text-sm"></label>
                      )}
                    </div>
                    <div>
                      <label className="text-black text-sm">Domain Address</label>
                      <input
                        id="domain-address"
                        name="domain-address"
                        type="url"
                        autoComplete="off"
                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                        placeholder="https://"
                        onChange={handleDomainChange}
                        value={domainAddress}
                        required
                      />
                      {domainerror ? (
                        <label className="text-red-500 text-sm">{domainerror}</label>
                      ) : (
                        <label className="text-black text-sm"></label>
                      )}
                    </div>
                  </div>
                  {/* <div>
                                        <button
                                            type="submit"
                                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <span className="absolute left-0 inset-y-0 flex items-center pl-3">

                                            </span>
                                            Sign in
                                        </button>
                                    </div> */}

                  {/*footer*/}
                  <div className="flex items-center justify-end p-6  rounded-b">
                    <button
                      className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Add
                    </button>
                    <button
                      className="bg-pink-500 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => {
                        setShowModal(false);
                        clearAll();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      <div className="container mx-auto  ">
        <div className="py-8">
          <div className="grid grid-cols-2">
            <h4 className="text-xl font-bold leading-tight flex items-center">
              All Channels{" "}
            </h4>

            <div className="flex justify-end">
              <button
                className="group relative flex inline-flex items-center justify-center w-10 h-10 mr-2 py-2 border-transparent text-sm font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="button"
                onClick={() => {
                  setShowModal(true);
                  setChannelName("");
                  setDomainAddress("");
                  setChannelStatus(true);
                }}
              >
                <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                  <path
                    d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>

          <div className="flex flex-col mt-6">
            <div className=" overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border-b border-gray-200 shadow rounded-lg">
                  <table className="min-w-full overflow-x-scroll divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Channel Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Domain
                        </th>

                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Status
                        </th>
                        {/* <th
                                                    scope="col"
                                                    className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                >
                                                    Role
                                                </th> */}
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Acton
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {allChannels.map((channel, i) => (
                        <tr
                          className="transition-all hover:bg-gray-100 hover:shadow-lg"
                          key={i}
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {channel.name}
                                </div>
                                {/* <div className="text-sm text-gray-500">Attendance Keeper</div> */}
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-blue-900">{channel.domain}</div>
                            {/* <div className="text-sm text-gray-500">Optimization</div> */}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap">
                            {channel.status ? (
                              <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                                Active
                              </span>
                            ) : (
                              <span className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-red-100 rounded-full">
                                Deactive
                              </span>
                            )}
                          </td>
                          <td className="inline-flex items-center px-6 py-4 text-sm font-medium  whitespace-nowrap">
                            <button
                              className="inline-flex items-center h-5 px-2 mr-3 text-sm text-blue-100 transition-colors duration-150 bg-blue-700 rounded-lg focus:shadow-outline hover:bg-blue-800"
                              type="button"
                              onClick={(e) => viewBlog(channel, e)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                />
                              </svg>
                            </button>

                            <button
                              className="inline-flex items-center h-5 px-2 mr-3 text-sm text-purple-100 transition-colors duration-150 bg-purple-700 rounded-lg focus:shadow-outline hover:bg-purple-800"
                              type="button"
                              onClick={() => {
                                setEditModal(true);
                                setChannelName(channel.name);
                                setDomainAddress(channel.domain);
                                setChannelStatus(channel.status);
                                setChannelId(channel.id);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                />
                              </svg>
                            </button>

                            <button
                              className="inline-flex items-center h-5 px-2 text-sm text-pink-100 transition-colors duration-150 bg-pink-700 rounded-lg focus:shadow-outline hover:bg-pink-800"
                              type="button"
                              onClick={(e) => showDeleteModalView(channel.id, e)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="flex items-center justify-center bg-white">
                    <Loader
                      type="MutatingDots"
                      color="#00BFFF"
                      height={80}
                      width={80}
                      visible={loadingBar}
                    />
                  </div>

                  <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                    <div className="flex-1 flex justify-between sm:hidden">
                      <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                        Previous
                      </span>
                      <span className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                        Next
                      </span>
                    </div>
                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div>
                        <p className="text-sm text-gray-700">
                          Showing{" "}
                          <span className="font-medium">{currentpage * 10 - 9}</span> to{" "}
                          <span className="font-medium">
                            {currentpage === totalPages
                              ? totalChannels
                              : currentpage * 10}
                          </span>{" "}
                          of <span className="font-medium">{totalChannels}</span> results
                        </p>
                      </div>
                      <div>
                        <nav
                          className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                          aria-label="Pagination"
                        >
                          {
                            // currentpage >=1 &&
                            <span
                              type="button"
                              onClick={(e) => fetchAllChannels(currentpage - 1)}
                              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                              <span className="sr-only">Previous</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          }
                          {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
                          {currentpage - 3 > 0 && (
                            <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                              ...
                            </span>
                          )}

                          {currentpage - 2 > 0 && (
                            <span
                              type="button"
                              onClick={(e) => fetchAllChannels(currentpage - 1)}
                              className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                            >
                              {currentpage - 2}
                            </span>
                          )}

                          {currentpage - 1 > 0 && (
                            <span
                              type="button"
                              onClick={(e) => fetchAllChannels(currentpage - 1)}
                              className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                            >
                              {currentpage - 1}
                            </span>
                          )}

                          <span
                            aria-current="page"
                            className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                          >
                            {currentpage}
                          </span>
                          {totalPages - currentpage >= 1 && (
                            <span
                              type="button"
                              onClick={(e) => fetchAllChannels(currentpage + 1)}
                              className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                            >
                              {currentpage + 1}
                            </span>
                          )}
                          {totalPages - currentpage >= 2 && (
                            <span
                              type="button"
                              onClick={(e) => fetchAllChannels(currentpage + 1)}
                              className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                            >
                              {currentpage + 2}
                            </span>
                          )}

                          {totalPages - currentpage >= 3 && (
                            <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                              ...
                            </span>
                          )}

                          {/* <span
                                                
                                                className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
                                                >
                                                8
                                                </span>
                                                <span
                                                
                                                className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                                >
                                                9
                                                </span>
                                                <span
                                                
                                                className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                                                >
                                                10
                                                </span> */}
                          {
                            // currentpage <= totalPages &&
                            <span
                              type="button"
                              onClick={(e) => fetchAllChannels(currentpage + 1)}
                              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            >
                              <span className="sr-only">Next</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          }
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <ToastContainer />
      </div>
    </div>
  );
}
export default Channels;
