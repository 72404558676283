import React, { Component } from "react";

import fetch from "../../configs/axios";

class ConfirmRegistration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validationCode: "",
      errorMessage: "",
    };
  }

  handleFormData = (e) => {
    this.setState({
      validationCode: e.target.value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = {
        email: localStorage.getItem("userEmail"),
        validation_code: this.state.validationCode,
      };
      console.log(data);

      const res = await fetch.post("/evident/auth/api/v1/user/confirm/", data);
      this.props.history.push("/login");
    } catch (error) {
      if (error.response) {
        this.setState({
          errorMessage: error.response.data.message,
        });
      } else {
        this.setState({
          errorMessage: "Something unexpected occurs, Please Try Again",
        });
      }
    }
  };

  // async validateEmail() {
  //   //console.log(this.props.location.state, this.state.validationCode)
  //   await axios
  //     .post(process.env.REACT_APP_AUTH_BASE_URL + "/api/v1/auth/confirm", {
  //       email: this.props.location.state,
  //       validation_code: this.state.validationCode,
  //     })
  //     .then((res) => {
  //       //console.log(res)
  //       this.props.history.push("/login");
  //     })
  //     .catch(
  //       function (error) {
  //         // handle error
  //         //errormsg = error.response.data.message
  //         if (error.response.data) {
  //           this.setState({
  //             errorMessage: error.response.data.message,
  //           });
  //         } else {
  //           this.setState({
  //             errorMessage: "Something unexpected occurs, Please Try Again",
  //           });
  //         }
  //       }.bind(this)
  //     );
  // }

  render() {
    return (
      <div>
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div>
              <img className="mx-auto h-12 w-auto" src="/logo192.png" alt="Workflow" />
              <p className="mt-2 text-center text-1x font-extrabold text-gray-900">
                Verification mail has been sent to
                <span className="text-red-900"> {this.props.location.state}</span>
              </p>
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                Enter Validation Pin Below
              </h2>
            </div>
            <form className="mt-8 space-y-6" onSubmit={this.handleSubmit}>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="rounded-md shadow-sm">
                <div className="mb-3">
                  <label htmlFor="pin-number" className="sr-only">
                    Pin
                  </label>
                  <input
                    id="pin-number"
                    name="pin"
                    type="text"
                    autoComplete="off"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-400 focus:border-gray-400 focus:z-10 sm:text-sm"
                    placeholder="Pin Number"
                    value={this.state.validationCode}
                    onChange={this.handleFormData}
                  />
                </div>

                {this.state.errorMessage !== "" && (
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-red-500"
                    >
                      {this.state.errorMessage}
                    </label>
                  </div>
                )}

                <div>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white evident-bg focus:outline-none"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmRegistration;
